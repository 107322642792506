import * as R from 'ramda';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import React, { useState, useMemo } from 'react';
import { useReactiveQuery } from 'poly-client-utils';
import { S } from 'poly-book';

import { propertiesForSelectQuery } from 'poly-site-ui';
import Select from '../../select/index.js';
import { SORTING } from '../../../constants/index.js';
import { propertiesChanged } from '../../../queries/index.js';
import { formatPlaceholder } from '../../../util/select.js';

function PropertyOptionRenderer({ label }) {
  return (
    <S type="content" title={label}>
      {label}
    </S>
  );
}

PropertyOptionRenderer.propTypes = {
  label: PropTypes.string.isRequired,
};

// eslint-disable-next-line import/no-unused-modules
export const defaultPropertyQuery = gql`
  query defaultPropertyQuery($id: ID!) {
    property(id: $id) {
      _id
      name
    }
  }
`;

// preparePropertyOption :: Property -> SelectOption
const preparePropertyOption = R.applySpec({
  value: R.prop('_id'),
  label: R.prop('name'),
});

// getPropertiesOptions :: Property -> SearchPropertiesResult -> [SelectOption]
const getPropertiesOptions = (defaultProperty) =>
  R.compose(
    R.uniqBy(R.prop('value')),
    R.when(
      () => !!defaultProperty,
      R.prepend(preparePropertyOption(defaultProperty)),
    ),
    R.map(preparePropertyOption),
    R.pathOr([], ['searchProperties', 'hits']),
  );

export function PropertySelect({ name, errors, onChange, value: selectValue }) {
  const [searchTerm, setSearchTerm] = useState('');

  const { data: defaultValue, loading: defaultValueLoading } = useQuery(
    defaultPropertyQuery,
    {
      variables: { id: selectValue },
      skip: !selectValue,
    },
  );

  const queryOptions = {
    variables: {
      input: { ...SORTING, searchTerm, size: 25 },
    },
  };

  const { data, loading } = useReactiveQuery(
    propertiesForSelectQuery,
    propertiesChanged,
    { queryOptions, subscriptionOptions: {} },
  );

  const isLoading = defaultValueLoading || loading;

  const noResultsText = R.ifElse(
    R.isEmpty,
    R.always(null),
    R.always('No Results Found'),
  )(searchTerm);

  const defaultProperty = useMemo(
    () => R.prop('property', defaultValue),
    [defaultValue],
  );

  const options = useMemo(
    () => getPropertiesOptions(defaultProperty)(data),
    [data, defaultProperty],
  );

  const onPropertySelect = (option) =>
    onChange({ target: { name, value: option ? option.value : option } });

  return (
    <Select
      name={name}
      options={options}
      value={selectValue}
      isLoading={isLoading}
      onInputChange={setSearchTerm}
      onChange={onPropertySelect}
      onSelectResetsInput={false}
      noResultsText={noResultsText}
      invalid={!!R.prop(name, errors)}
      optionRenderer={PropertyOptionRenderer}
      placeholder={formatPlaceholder('Property')}
    />
  );
}

PropertySelect.displayName = 'AssetModalFormPropertySelect';
PropertySelect.propTypes = {
  value: PropTypes.string,
  errors: PropTypes.shape({
    property: PropTypes.string,
  }),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
