import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'poly-utils';
import EditResponseTeam from '../../../../components/project/details/response-team/edit.js';
import { setResponseTeamModal } from '../../../../redux/actions/index.js';
import { useProjectDetails } from '../../../../hooks/project-details.js';

const ResponseTeam = React.memo((props) => {
  const dispatch = useDispatch();

  const projectId = useSelector(
    (state) => state.projects.modals.responseTeam.projectId,
  );

  const [activeTabState, setActiveTabState] = useState('technicians');
  const [searchTerm, setSearchTerm] = useState('');

  const { project } = useProjectDetails(projectId);

  const setActiveTab = ({ target: { value } }) => setActiveTabState(value);

  const debouncedSearch = useCallback(debounce(300)(setSearchTerm), []);

  const onInputChange = ({ target: { value } }) => {
    debouncedSearch(value.trim());
  };

  const closeModal = () => dispatch(setResponseTeamModal(null));

  return (
    <EditResponseTeam
      {...props}
      closeModal={closeModal}
      projectId={projectId}
      activeTab={activeTabState}
      setActiveTab={setActiveTab}
      onInputChange={onInputChange}
      project={project}
      searchTerm={searchTerm}
    />
  );
});

export default function (props) {
  const isModalOpen = useSelector(
    (state) => state.projects.modals.responseTeam,
  );

  return isModalOpen ? <ResponseTeam {...props} isModalOpen /> : null;
}
