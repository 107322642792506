import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import React, { useState, useCallback } from 'react';
import { ConfirmModal } from 'poly-site-ui';

import {
  GeneralError,
  ApproveSeveralInvoicesSuccess,
} from '../../constants/alerts.js';
import {
  setInvoicesModal,
  setSelectedInvoices,
} from '../../redux/actions/index.js';
import { useInvoicesFilters } from '../../utils/invoices/invoices-filters.js';
import { makeBold, formatCurrency } from '../../util/general.js';
import {
  useApproveMultipleInvoices,
  useInvoicesQuery,
} from '../../hooks/invoices.js';

// getAmount :: Array -> Object
const getAmount = (value) => R.sum(value.map(R.prop('total')));

export default function InvoicesConfirmModal(props) {
  const showModal = useSelector(
    (state) => state.invoices.modals.approveInvoice,
  );

  const selectedInvoices = useSelector(
    (state) => state.invoices.selectedInvoices,
  );

  const type = useSelector((state) => state.invoices.type);

  const invoicesState = useSelector((state) => state.invoices);

  const dispatch = useDispatch();

  const [loadingState, setLoadingState] = useState(false);
  const [error, setError] = useState(null);

  const { searchTerm, query, size } = useInvoicesFilters(invoicesState);

  const { invoices, total, invoicesLoading, restInvoicesProps } =
    useInvoicesQuery({ searchTerm, query, pageSize: size });

  const { approveInvoices } = useApproveMultipleInvoices();

  const closeModal = useCallback(() =>
    dispatch(
      setInvoicesModal({
        approveInvoice: false,
      }),
      [dispatch, setInvoicesModal],
    ),
  );

  const onConfirm = useCallback(async () => {
    setLoadingState(true);
    return approveInvoices({ invoicesIds: selectedInvoices })
      .then(() => {
        setLoadingState(false);
        dispatch(setSelectedInvoices([]));
        dispatch(setInvoicesModal({ approveInvoice: false }));
        toast.success(ApproveSeveralInvoicesSuccess(selectedInvoices.length));
      })
      .catch(() => {
        toast.error(GeneralError);
        setLoadingState(false);
      });
  }, [dispatch, selectedInvoices, approveInvoices, setLoadingState]);

  const filtered = invoices.filter(({ _id }) =>
    R.includes(_id, selectedInvoices),
  );

  const amount = makeBold(formatCurrency(getAmount(filtered)));

  const text = (
    <>
      You are about to approve {selectedInvoices.length} invoices for a total of{' '}
      {amount}
      .
      <br />
      Approve to process.
    </>
  );

  if (!showModal || invoicesLoading) return null;

  return (
    <ConfirmModal
      {...props}
      {...restInvoicesProps}
      total={total}
      showModal={showModal}
      closeModal={closeModal}
      loading={loadingState}
      error={error}
      onConfirm={onConfirm}
      subHeading="Confirmation"
      text={text}
      setError={setError}
      type={type}
    />
  );
}
